// Labels for buttons
// --------------------------------------------------

.button-label-size(@padding-vertical; @padding-horizontal; @border-radius) {
  padding: (@padding-vertical - 1px) @padding-horizontal (@padding-vertical + 1px) @padding-horizontal;
  left: (-1 * @padding-horizontal);
  border-radius: (@border-radius - 1px) 0 0 (@border-radius - 1px);

  &.btn-label-right {
    left: auto;
    right: (-1 * @padding-horizontal);
    border-radius: 0 (@border-radius - 1px) (@border-radius - 1px) 0;
  }
}

.btn-labeled {
  padding-top: 0;
  padding-bottom: 0;

  &.btn-block {
    text-indent: -12px;
    line-height: 32px;

    &.btn-lg {
      line-height: 42px;
      text-indent: -16px;
    }

    &.btn-sm {
      line-height: 30px;
      text-indent: -10px;
    }

    &.btn-xs {
      line-height: 22px;
      text-indent: -5px;
    }

    .btn-label {
      float: left;
      line-height: 20px;
      text-indent: 0;
    }
  }
}

.btn-label {
  position: relative;
  background: transparent;
  background: rgba(0, 0, 0, 0.15);
  display: inline-block;
  .button-label-size(@padding-base-vertical; @padding-base-horizontal; @border-radius-base);
}

.btn-lg .btn-label {
  .button-label-size(@padding-large-vertical; @padding-large-horizontal; @border-radius-large);
}
.btn-sm .btn-label {
  .button-label-size(@padding-small-vertical; @padding-small-horizontal; @border-radius-small);
}
.btn-xs .btn-label {
  .button-label-size(1px; 5px; @border-radius-small);
}
//Fix bootstrap grouped buttons
.btn-group {
    .btn-labeled:not(:first-child) .btn-label:not(.btn-label-right) {
      .border-left-radius(0px);
    }
    .btn-labeled:not(:last-child) .btn-label.btn-label-right {
      .border-right-radius(0px);
    }
}
